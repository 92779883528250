/** @jsx jsx */
import { graphql } from 'gatsby';
import * as React from 'react';
import Header from '../components/Common/Header';
import Layout from '../components/Layout/index';
import SectionHeader from '../components/Common/SectionHeader';
import BlogCard from '../components/Common/BlogCard';
import { jsx } from 'theme-ui';
import { Seo } from '../components/Seo';

const StoryPage = ({ data }) => {
  const { posts } = data;
  return (
    <Layout>
      <Seo
        title="Insightful, inspiring and personal embryo adoption stories | National Embryo Donation Center"
        description="Enjoy these embryo adoption stories from couples who have built their families from donated embryos through the NEDC"
      />
      <div>
        <Header
          title="Personal Stories"
          video={'https://www.youtube.com/watch?v=emut6Fd_Xc0'}
          // background={'/images/header/'}
        />
        <section>
          <div className="content">
            <SectionHeader heading="Personal Stories" styles={{ pt: [null, null, null, '3rem'] }} />
            <p
              sx={{
                variant: 'text.normal',
              }}
            >
              You can read all the factual information available. But sometimes you need to hear
              someone’s story to understand what will happen when you donate or adopt embryos. These
              embryo adoption stories give you insight into this very personal journey.
            </p>
          </div>
        </section>
        <section>
          <div className="content">
            <div
              sx={{
                display: 'flex',
                gap: '1.5rem',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
              }}
            >
              {posts.nodes.map((post) => (
                <BlogCard key={post.id} data={post} />
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default StoryPage;

export const query = graphql`
  query StoriesPageQuery {
    posts: allDegaPost {
      nodes {
        users {
          id
          first_name
          last_name
        }
        categories {
          slug
          name
        }
        medium {
          alt_text
          url
          dimensions
        }
        published_date
        id
        status
        subtitle
        title
        slug
        excerpt
      }
    }
  }
`;
